import React, { useState, useEffect } from 'react';

interface PuntoContacto {
  fecha: string;
  descripcion: string;
}

interface ProcesoRecepcion {
  aceptacionEntrada: string;
  inicioCarga: string;
  finCarga: string;
  salida: string;
}

interface EnvioTrazabilidad {
  id: string;
  estado: string;
  fechaActualizacion: string;
  ubicacion: string;
  puntosContacto: PuntoContacto[];
  procesoRecepcion?: ProcesoRecepcion;
  fillRate: number;
}

const Trazabilidad: React.FC = () => {
  const [envios, setEnvios] = useState<EnvioTrazabilidad[]>([]);
  const [expandido, setExpandido] = useState<string | null>(null);

  useEffect(() => {
    // Aquí normalmente harías una llamada a una API para obtener los datos de trazabilidad
    // Por ahora, usaremos datos de ejemplo
    const datosEjemplo: EnvioTrazabilidad[] = [
      {
        id: 'ENV001',
        estado: 'En transito',
        fechaActualizacion: '2023-05-20 10:30',
        ubicacion: 'Centro de distribucion',
        puntosContacto: [
          { fecha: '2023-05-19 08:00', descripcion: 'Pedido recibido' },
          { fecha: '2023-05-19 14:00', descripcion: 'Pedido procesado' },
          { fecha: '2023-05-20 09:00', descripcion: 'En ruta de entrega' },
        ],
        fillRate: 0.2, // 20% de fill rate
      },
      {
        id: 'ENV002',
        estado: 'Entregado',
        fechaActualizacion: '2023-05-19 15:45',
        ubicacion: 'Supermercado A',
        puntosContacto: [
          { fecha: '2023-05-18 10:00', descripcion: 'Pedido recibido' },
          { fecha: '2023-05-18 16:00', descripcion: 'Pedido procesado' },
          { fecha: '2023-05-19 09:00', descripcion: 'En ruta de entrega' },
          { fecha: '2023-05-19 15:45', descripcion: 'Entregado al cliente' },
        ],
        procesoRecepcion: {
          aceptacionEntrada: '2023-05-19 15:30',
          inicioCarga: '2023-05-19 15:35',
          finCarga: '2023-05-19 15:40',
          salida: '2023-05-19 15:45',
        },
        fillRate: 0.4, // 40% de fill rate
      },
      {
        id: 'ENV003',
        estado: 'En transito',
        fechaActualizacion: '2023-05-21 11:15',
        ubicacion: 'En camión de reparto',
        puntosContacto: [
          { fecha: '2023-05-20 09:00', descripcion: 'Pedido recibido' },
          { fecha: '2023-05-20 14:30', descripcion: 'Pedido procesado' },
          { fecha: '2023-05-21 08:00', descripcion: 'En ruta de entrega' },
        ],
        fillRate: 0.7, // 70% de fill rate
      },
      {
        id: 'ENV004',
        estado: 'Entregado',
        fechaActualizacion: '2023-05-22 16:30',
        ubicacion: 'Supermercado B',
        puntosContacto: [
          { fecha: '2023-05-21 10:00', descripcion: 'Pedido recibido' },
          { fecha: '2023-05-21 15:00', descripcion: 'Pedido procesado' },
          { fecha: '2023-05-22 09:30', descripcion: 'En ruta de entrega' },
          { fecha: '2023-05-22 16:30', descripcion: 'Entregado al cliente' },
        ],
        procesoRecepcion: {
          aceptacionEntrada: '2023-05-22 16:15',
          inicioCarga: '2023-05-22 16:20',
          finCarga: '2023-05-22 16:25',
          salida: '2023-05-22 16:30',
        },
        fillRate: 1, // 100% de fill rate
      },
      {
        id: 'ENV005',
        estado: 'Entregado',
        fechaActualizacion: '2023-05-23 14:45',
        ubicacion: 'Supermercado C',
        puntosContacto: [
          { fecha: '2023-05-22 11:00', descripcion: 'Pedido recibido' },
          { fecha: '2023-05-22 16:30', descripcion: 'Pedido procesado' },
          { fecha: '2023-05-23 08:45', descripcion: 'En ruta de entrega' },
          { fecha: '2023-05-23 14:45', descripcion: 'Entregado al cliente' },
        ],
        procesoRecepcion: {
          aceptacionEntrada: '2023-05-23 14:30',
          inicioCarga: '2023-05-23 14:35',
          finCarga: '2023-05-23 14:40',
          salida: '2023-05-23 14:45',
        },
        fillRate: 1, // 100% de fill rate
      },
    ];
    setEnvios(datosEjemplo);
  }, []);

  const toggleExpansion = (id: string) => {
    setExpandido(expandido === id ? null : id);
  };

  const getFillRateColor = (fillRate: number) => {
    if (fillRate >= 0.9) return 'bg-green-500';
    if (fillRate >= 0.7) return 'bg-yellow-500';
    if (fillRate >= 0.4) return 'bg-orange-500';
    return 'bg-red-500';
  };

  return (
    <div className="bg-gray-50 p-4 sm:p-6 rounded-lg shadow-md">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-gray-800">Trazabilidad de Envíos</h2>
      <div className="space-y-4">
        {envios.map((envio) => (
          <div key={envio.id} className="bg-white p-3 sm:p-4 rounded-lg shadow">
            <div className="mb-2">
              <div className="w-full bg-gray-200 rounded-full h-2 sm:h-2.5 mb-1">
                <div
                  className={`h-2 sm:h-2.5 rounded-full ${getFillRateColor(envio.fillRate)}`}
                  style={{ width: `${envio.fillRate * 100}%` }}
                ></div>
              </div>
              <p className="text-xs text-gray-600 text-right">Fill Rate: {(envio.fillRate * 100).toFixed(1)}%</p>
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center cursor-pointer" onClick={() => toggleExpansion(envio.id)}>
              <h3 className="text-base sm:text-lg font-semibold mb-1 sm:mb-0">{envio.id}</h3>
              <span className={`px-2 py-1 text-xs font-semibold rounded-full mt-1 sm:mt-0 ${
                envio.estado === 'Entregado' ? 'bg-green-100 text-green-800' :
                envio.estado === 'En tránsito' ? 'bg-blue-100 text-blue-800' :
                'bg-yellow-100 text-yellow-800'
              }`}>
                {envio.estado}
              </span>
            </div>
            <p className="text-xs sm:text-sm text-gray-600 mt-1">Última actualización: {envio.fechaActualizacion}</p>
            <p className="text-xs sm:text-sm text-gray-600">Ubicación: {envio.ubicacion}</p>
            
            {expandido === envio.id && (
              <div className="mt-3 sm:mt-4 border-t pt-3 sm:pt-4 bg-gray-50 p-3 sm:p-4 rounded-lg">
                <h4 className="font-semibold mb-2 text-sm sm:text-base text-gray-700">Puntos de Contacto:</h4>
                <ul className="list-disc list-inside space-y-1">
                  {envio.puntosContacto.map((punto, index) => (
                    <li key={index} className="text-xs sm:text-sm text-gray-600">
                      <span className="font-medium text-gray-700">{punto.fecha}:</span> {punto.descripcion}
                    </li>
                  ))}
                </ul>
                
                {envio.procesoRecepcion && (
                  <div className="mt-3 sm:mt-4">
                    <h4 className="font-semibold mb-2 text-sm sm:text-base text-gray-700">Proceso de Recepción:</h4>
                    <ul className="list-disc list-inside space-y-1">
                      <li className="text-xs sm:text-sm text-gray-600"><span className="font-medium text-gray-700">Aceptación de entrada:</span> {envio.procesoRecepcion.aceptacionEntrada}</li>
                      <li className="text-xs sm:text-sm text-gray-600"><span className="font-medium text-gray-700">Inicio de descarga:</span> {envio.procesoRecepcion.inicioCarga}</li>
                      <li className="text-xs sm:text-sm text-gray-600"><span className="font-medium text-gray-700">Fin de descarga:</span> {envio.procesoRecepcion.finCarga}</li>
                      <li className="text-xs sm:text-sm text-gray-600"><span className="font-medium text-gray-700">Salida:</span> {envio.procesoRecepcion.salida}</li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Trazabilidad;
