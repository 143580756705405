import React from 'react';
import { useParams } from 'react-router-dom';

interface Horario {
  fecha: string;
  hora: string;
  disponible: boolean;
}

const horariosMock: Horario[] = [
  { fecha: '2023-05-20', hora: '09:00', disponible: false },
  { fecha: '2023-05-20', hora: '10:00', disponible: true },
  { fecha: '2023-05-20', hora: '11:00', disponible: false },
  { fecha: '2023-05-21', hora: '09:00', disponible: true },
  { fecha: '2023-05-21', hora: '10:00', disponible: false },
  { fecha: '2023-05-21', hora: '11:00', disponible: true },
];

const CalendarioLocal: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Agrupar horarios por fecha
  const horariosPorFecha = horariosMock.reduce((acc, horario) => {
    if (!acc[horario.fecha]) {
      acc[horario.fecha] = [];
    }
    acc[horario.fecha].push(horario);
    return acc;
  }, {} as Record<string, Horario[]>);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Calendario del Local {id}</h2>
      {Object.entries(horariosPorFecha).map(([fecha, horarios]) => (
        <div key={fecha} className="mb-6">
          <h3 className="text-lg font-semibold mb-2 text-gray-700">{fecha}</h3>
          <div className="grid grid-cols-4 gap-2">
            {horarios.map((horario, index) => (
              <div 
                key={index}
                className={`p-2 rounded ${
                  horario.disponible 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}
              >
                {horario.hora} - {horario.disponible ? 'Disponible' : 'Ocupado'}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CalendarioLocal;
