import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Orden {
  id: number;
  cliente: string;
  producto: string;
  estado: string;
  fechaCreacion: string;
}

const Ordenes: React.FC = () => {
  const [ordenes, setOrdenes] = useState<Orden[]>([]);
  const [filteredOrdenes, setFilteredOrdenes] = useState<Orden[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Aquí deberías hacer una llamada a tu API para obtener las órdenes
    // Por ahora, usaremos datos de ejemplo
    const ordenesEjemplo: Orden[] = [
      { id: 1, cliente: 'Cliente A', producto: 'Producto 1', estado: 'Pendiente', fechaCreacion: '2023-05-01' },
      { id: 2, cliente: 'Cliente B', producto: 'Producto 2', estado: 'En proceso', fechaCreacion: '2023-05-02' },
      { id: 3, cliente: 'Cliente C', producto: 'Producto 3', estado: 'Completado', fechaCreacion: '2023-05-03' },
    ];
    setOrdenes(ordenesEjemplo);
    setFilteredOrdenes(ordenesEjemplo);
  }, []);

  useEffect(() => {
    const results = ordenes.filter(orden =>
      Object.values(orden).some(value =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredOrdenes(results);
  }, [searchTerm, ordenes]);

  const handleAgendamiento = (orden: Orden) => {
    navigate(`/dashboard/agendamiento`, { 
      state: { 
        ordenId: orden.id,
        cliente: orden.cliente,
        producto: orden.producto,
        fechaCreacion: orden.fechaCreacion
      } 
    });
  };

  const handleTrazabilidad = (ordenId: number) => {
    navigate(`/dashboard/trazabilidad`, { state: { ordenId } });
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold mb-4 text-gray-800">Órdenes</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar órdenes..."
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700 placeholder-gray-500 bg-white shadow-sm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border-b text-left text-gray-600">ID</th>
              <th className="py-2 px-4 border-b text-left text-gray-600">Cliente</th>
              <th className="py-2 px-4 border-b text-left text-gray-600">Producto</th>
              <th className="py-2 px-4 border-b text-left text-gray-600">Estado</th>
              <th className="py-2 px-4 border-b text-left text-gray-600">Fecha de Creación</th>
              <th className="py-2 px-4 border-b text-left text-gray-600">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrdenes.map((orden) => (
              <tr key={orden.id} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b text-gray-800">{orden.id}</td>
                <td className="py-2 px-4 border-b text-gray-800">{orden.cliente}</td>
                <td className="py-2 px-4 border-b text-gray-800">{orden.producto}</td>
                <td className="py-2 px-4 border-b text-gray-800">{orden.estado}</td>
                <td className="py-2 px-4 border-b text-gray-800">{orden.fechaCreacion}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleAgendamiento(orden)}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded mr-2 transition duration-300"
                  >
                    Agendar
                  </button>
                  <button
                    onClick={() => handleTrazabilidad(orden.id)}
                    className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded transition duration-300"
                  >
                    Trazabilidad
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Ordenes;
