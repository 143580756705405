import React, { useState } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Inicio from './Inicio';
import Agendamiento from './Agendamiento';
import EstadoSolicitudes from './EstadoSolicitudes';
import DetalleSolicitud from './DetalleSolicitud';
import AgendaProveedores from './AgendaProveedores';
import CalendarioLocal from './CalendarioLocal';
import Trazabilidad from './Trazabilidad';
import Ordenes from './Ordenes'; // Asegúrate de crear este componente

const Dashboard: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    closeMenu();
  };

  const NavLinks = () => (
    <>
      <button onClick={() => handleNavigation('/dashboard')} className="w-full text-left py-2 px-4 text-black hover:text-gray-600 bg-transparent md:hover:bg-blue-500 md:hover:text-white transition-colors duration-200">Inicio</button>
      <button onClick={() => handleNavigation('/dashboard/agendamiento')} className="w-full text-left py-2 px-4 text-black hover:text-gray-600 bg-transparent md:hover:bg-blue-500 md:hover:text-white transition-colors duration-200">Agendamiento</button>
      <button onClick={() => handleNavigation('/dashboard/estado-solicitudes')} className="w-full text-left py-2 px-4 text-black hover:text-gray-600 bg-transparent md:hover:bg-blue-500 md:hover:text-white transition-colors duration-200">Estado de mis solicitudes</button>
      <button onClick={() => handleNavigation('/dashboard/agenda-proveedores')} className="w-full text-left py-2 px-4 text-black hover:text-gray-600 bg-transparent md:hover:bg-blue-500 md:hover:text-white transition-colors duration-200">Agenda de Locales</button>
      <button onClick={() => handleNavigation('/dashboard/trazabilidad')} className="w-full text-left py-2 px-4 text-black hover:text-gray-600 bg-transparent md:hover:bg-blue-500 md:hover:text-white transition-colors duration-200">Trazabilidad</button>
      <button onClick={() => handleNavigation('/dashboard/ordenes')} className="w-full text-left py-2 px-4 text-black hover:text-gray-600 bg-transparent md:hover:bg-blue-500 md:hover:text-white transition-colors duration-200">Órdenes</button>
    </>
  );

  return (
    <div className="flex flex-col md:flex-row h-screen bg-white relative">
      {/* Header */}
      <div className="bg-white p-4 flex justify-between items-center md:hidden">
        <h1 className="text-2xl font-bold text-blue-600">Nexomovil</h1>
      </div>

      {/* Sidebar for desktop */}
      <div className="hidden md:block w-64 bg-white p-4 border-r border-gray-200">
        <h1 className="text-2xl font-bold text-blue-600 mb-6">Nexomovil</h1>
        <nav>
          <NavLinks />
        </nav>
      </div>
      
      {/* Main content */}
      <div className="flex-1 p-4 md:p-8 overflow-auto bg-white">
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/agendamiento" element={<Agendamiento />} />
          <Route path="/estado-solicitudes" element={<EstadoSolicitudes />} />
          <Route path="/detalle-solicitud/:id" element={<DetalleSolicitud />} />
          <Route path="/agenda-proveedores" element={<AgendaProveedores />} />
          <Route path="/agenda-proveedores/:id" element={<CalendarioLocal />} />
          <Route path="/trazabilidad" element={<Trazabilidad />} />
          <Route path="/ordenes" element={<Ordenes />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </div>

      {/* Mobile menu button */}
      <button 
        onClick={toggleMenu} 
        className="md:hidden fixed right-4 bottom-4 bg-blue-500 text-white p-3 rounded-full shadow-lg z-50"
      >
        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          {menuOpen ? (
            <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
          ) : (
            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
          )}
        </svg>
      </button>

      {/* Mobile menu */}
      <div className={`md:hidden fixed inset-x-0 bottom-0 bg-white bg-opacity-90 transition-transform duration-300 ease-in-out transform ${menuOpen ? 'translate-y-0' : 'translate-y-full'}`}>
        <nav className="p-4">
          <NavLinks />
        </nav>
      </div>
    </div>
  );
};

export default Dashboard;