import React from 'react';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Inicio: React.FC = () => {
  const tiemposPromedio = {
    labels: ['Perecederos', 'No Perecederos A', 'No Perecederos B'],
    datasets: [
      {
        label: 'Tiempo promedio de procesamiento (minutos)',
        data: [45, 60, 75],
        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Tiempos Promedio por Tipo de Solicitud',
      },
    },
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Bienvenido al Dashboard</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-blue-100 p-4 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2 text-blue-800">Solicitudes Pendientes</h3>
          <p className="text-3xl font-bold text-blue-600">5</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2 text-green-800">Solicitudes Aprobadas</h3>
          <p className="text-3xl font-bold text-green-600">12</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-2 text-yellow-800">Solicitudes en Proceso</h3>
          <p className="text-3xl font-bold text-yellow-600">3</p>
        </div>
      </div>
      
      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Tiempos Promedio de Procesamiento</h3>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <Bar data={tiemposPromedio} options={options} />
        </div>
      </div>
      
      <div className="bg-gray-50 p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Acciones Rápidas</h3>
        <div className="space-y-4">
          <Link to="/dashboard/agendamiento" className="block w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
            Nueva Solicitud de Agendamiento
          </Link>
          <Link to="/dashboard/estado-solicitudes" className="block w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300">
            Ver Estado de Solicitudes
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Inicio;