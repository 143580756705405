import React, { useState } from 'react';

interface SolicitudFormProps {
  onSubmit: (formData: {
    solicitante: string;
    email: string;
    telefono: string;
    fechaDeseada: string;
    turnoEntrega: string;
    numeroCamiones: number;
    palletsEstimados: number;
    bultosEstimados: number;
    volumenEstimado: number;
    tipoEntrega: string;
    comentarios: string;
    numeroOrden: string;
    cantidadDespachar: number;
  }) => void;
}

const SolicitudForm: React.FC<SolicitudFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    solicitante: '',
    email: '',
    telefono: '',
    fechaDeseada: '',
    turnoEntrega: '',
    numeroCamiones: 0,
    palletsEstimados: 0,
    bultosEstimados: 0,
    volumenEstimado: 0,
    tipoEntrega: '',
    comentarios: '',
    numeroOrden: '',
    cantidadDespachar: 0
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-blue-700">Solicitud de Cita</h2>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label htmlFor="solicitante" className="block text-sm font-medium text-gray-700 mb-1">Solicitante *</label>
          <input
            type="text"
            id="solicitante"
            name="solicitante"
            value={formData.solicitante}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="numeroCamiones" className="block text-sm font-medium text-gray-700 mb-1">No. Camiones *</label>
          <input
            type="number"
            id="numeroCamiones"
            name="numeroCamiones"
            value={formData.numeroCamiones}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="palletsEstimados" className="block text-sm font-medium text-gray-700 mb-1">Pallets Estimados *</label>
          <input
            type="number"
            id="palletsEstimados"
            name="palletsEstimados"
            value={formData.palletsEstimados}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="telefono" className="block text-sm font-medium text-gray-700 mb-1">Teléfono *</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="bultosEstimados" className="block text-sm font-medium text-gray-700 mb-1">Bultos Estimados *</label>
          <input
            type="number"
            id="bultosEstimados"
            name="bultosEstimados"
            value={formData.bultosEstimados}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="fechaDeseada" className="block text-sm font-medium text-gray-700 mb-1">Fecha deseada *</label>
          <input
            type="date"
            id="fechaDeseada"
            name="fechaDeseada"
            value={formData.fechaDeseada}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="volumenEstimado" className="block text-sm font-medium text-gray-700 mb-1">Volumen Estimado (m³) *</label>
          <input
            type="number"
            id="volumenEstimado"
            name="volumenEstimado"
            value={formData.volumenEstimado}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          />
        </div>
        <div>
          <label htmlFor="turnoEntrega" className="block text-sm font-medium text-gray-700 mb-1">Turno de entrega CD *</label>
          <select
            id="turnoEntrega"
            name="turnoEntrega"
            value={formData.turnoEntrega}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          >
            <option value="">Seleccione un turno</option>
            <option value="Turno Proveedores (08:00 - 18:00)">Turno Proveedores (08:00 - 18:00)</option>
            {/* Añadir más opciones según sea necesario */}
          </select>
        </div>
        <div>
          <label htmlFor="tipoEntrega" className="block text-sm font-medium text-gray-700 mb-1">Tipo *</label>
          <select
            id="tipoEntrega"
            name="tipoEntrega"
            value={formData.tipoEntrega}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            required
          >
            <option value="">Seleccione un tipo</option>
            <option value="Directa">Directa</option>
            {/* Añadir más opciones según sea necesario */}
          </select>
        </div>
      </div>
      <div className="mt-6">
        <label htmlFor="comentarios" className="block text-sm font-medium text-gray-700 mb-1">Comentarios</label>
        <textarea
          id="comentarios"
          name="comentarios"
          value={formData.comentarios}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
          rows={3}
        ></textarea>
      </div>
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2 text-gray-800">Entrega estimada</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="numeroOrden" className="block text-sm font-medium text-gray-700 mb-1">Número de Orden</label>
            <input
              type="text"
              id="numeroOrden"
              name="numeroOrden"
              value={formData.numeroOrden}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            />
          </div>
          <div>
            <label htmlFor="cantidadDespachar" className="block text-sm font-medium text-gray-700 mb-1">Cantidad a despachar</label>
            <input
              type="number"
              id="cantidadDespachar"
              name="cantidadDespachar"
              value={formData.cantidadDespachar}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
            />
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end space-x-4">
        <button type="button" className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500">CANCELAR</button>
        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">CONTINUAR</button>
      </div>
    </form>
  );
};

export default SolicitudForm;