import React from 'react';
import { useParams, Link } from 'react-router-dom';

interface SolicitudDetallada {
  id: string;
  tipoCarga: string;
  cantidadPallets: number;
  fecha: string;
  hora: string;
  estado: string;
  proveedor: string;
}

const solicitudesDetalladasMock: SolicitudDetallada[] = [
  { id: '1', tipoCarga: 'Perecederos', cantidadPallets: 10, fecha: '2023-05-15', hora: '10:30', estado: 'Pendiente', proveedor: 'Proveedor A' },
  { id: '2', tipoCarga: 'No Perecederos A', cantidadPallets: 15, fecha: '2023-05-18', hora: '14:45', estado: 'Aprobada', proveedor: 'Proveedor B' },
  { id: '3', tipoCarga: 'No Perecederos B', cantidadPallets: 8, fecha: '2023-05-20', hora: '09:15', estado: 'En proceso', proveedor: 'Proveedor C' },
];

const DetalleSolicitud: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const solicitud = solicitudesDetalladasMock.find(s => s.id === id);

  if (!solicitud) {
    return <div className="text-gray-800">Solicitud no encontrada</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Detalle de Solicitud #{solicitud.id}</h2>
      <div className="grid grid-cols-2 gap-4 text-gray-700">
        <p><span className="font-medium">ID de Solicitud:</span> {solicitud.id}</p>
        <p><span className="font-medium">Proveedor:</span> {solicitud.proveedor}</p>
        <p><span className="font-medium">Tipo de carga:</span> {solicitud.tipoCarga}</p>
        <p><span className="font-medium">Cantidad de pallets:</span> {solicitud.cantidadPallets}</p>
        <p><span className="font-medium">Fecha:</span> {solicitud.fecha}</p>
        <p><span className="font-medium">Hora:</span> {solicitud.hora}</p>
        <p><span className="font-medium">Estado:</span> 
          <span className={`ml-2 px-2 py-1 rounded text-white ${
            solicitud.estado === 'Pendiente' ? 'bg-yellow-500' :
            solicitud.estado === 'Aprobada' ? 'bg-green-500' :
            solicitud.estado === 'En proceso' ? 'bg-blue-500' : 'bg-gray-500'
          }`}>
            {solicitud.estado}
          </span>
        </p>
      </div>
      <Link 
        to="/dashboard/estado-solicitudes" 
        className="mt-6 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Volver a la lista de solicitudes
      </Link>
    </div>
  );
};

export default DetalleSolicitud;