import React, { useState } from 'react';
import SolicitudForm from './SolicitudForm';
import DecisionAlgorithm from './DecisionAlgorithm';
import AgendaVisualizacion from './AgendaVisualizacion';
import { SolicitudData, Alternativa } from '../types';

const Agendamiento: React.FC = () => {
  const [solicitudActual, setSolicitudActual] = useState<SolicitudData | null>(null);
  const [horasAsignadas, setHorasAsignadas] = useState<Alternativa[]>([]);
  const [ejecutarAlgoritmo, setEjecutarAlgoritmo] = useState(false);

  const handleSolicitudSubmit = (formData: {
    solicitante: string;
    email: string;
    telefono: string;
    fechaDeseada: string;
    turnoEntrega: string;
    numeroCamiones: number;
    palletsEstimados: number;
    bultosEstimados: number;
    volumenEstimado: number;
    tipoEntrega: string;
    comentarios: string;
    numeroOrden: string;
    cantidadDespachar: number;
  }) => {
    const solicitud: SolicitudData = {
      ...formData,
      ordenId: parseInt(formData.numeroOrden) || Math.floor(Math.random() * 1000000), // Genera un ID aleatorio si no hay número de orden
      cantidadSolicitada: formData.cantidadDespachar,
      cliente: 'Cliente Genérico',
      producto: 'Producto Genérico',
      fechaCreacion: new Date().toISOString().split('T')[0],
      cantidadTotal: formData.cantidadDespachar,
      unidadMedida: 'unidades',
      numeroRondas: formData.numeroCamiones
    };

    setSolicitudActual(solicitud);
    setHorasAsignadas([]);
    setEjecutarAlgoritmo(true);
  };

  const handleAsignacionHora = (alternativas: Alternativa[]) => {
    setHorasAsignadas(alternativas);
    setEjecutarAlgoritmo(false);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Agendamiento de Camiones</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Nueva Solicitud</h3>
          <SolicitudForm onSubmit={handleSolicitudSubmit} />
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Decisión del Algoritmo</h3>
          {solicitudActual && (
            <DecisionAlgorithm 
              solicitud={solicitudActual} 
              onAsignacionHora={handleAsignacionHora}
              ejecutarAlgoritmo={ejecutarAlgoritmo}
            />
          )}
        </div>
      </div>
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-2 text-gray-700">Visualización de Agenda</h3>
        <AgendaVisualizacion alternativas={horasAsignadas} />
      </div>
    </div>
  );
};

export default Agendamiento;