import React, { useState, useEffect } from 'react';
import { SolicitudData, Alternativa } from '../types';

interface DecisionResult {
  status: 'procesando' | 'asignado';
  message: string;
  alternativas: Alternativa[];
}

interface DecisionAlgorithmProps {
  solicitud: SolicitudData;
  onAsignacionHora: (alternativas: Alternativa[]) => void;
  ejecutarAlgoritmo: boolean;
}

const DecisionAlgorithm: React.FC<DecisionAlgorithmProps> = ({ solicitud, onAsignacionHora, ejecutarAlgoritmo }) => {
  const [decision, setDecision] = useState<DecisionResult>({
    status: 'procesando',
    message: 'Esperando para procesar la solicitud...',
    alternativas: []
  });

  useEffect(() => {
    if (!ejecutarAlgoritmo) return;

    setDecision({
      status: 'procesando',
      message: 'Procesando solicitud...',
      alternativas: []
    });

    const timerId = setTimeout(() => {
      const alternativas = generarAlternativas(solicitud);
      setDecision({
        status: 'asignado',
        message: 'Hemos encontrado los horarios más óptimos para tus camiones:',
        alternativas
      });

      onAsignacionHora(alternativas);
    }, 1500);

    return () => clearTimeout(timerId);
  }, [solicitud, onAsignacionHora, ejecutarAlgoritmo]);

  const generarAlternativas = (solicitud: SolicitudData): Alternativa[] => {
    const alternativas: Alternativa[] = [];
    const horaBase = new Date();
    horaBase.setHours(8, 0, 0, 0); // Comenzamos a las 8:00 AM

    for (let i = 0; i < solicitud.numeroCamiones; i++) {
      const horaAsignada = new Date(horaBase.getTime() + i * 30 * 60000); // Añadimos 30 minutos por cada camión
      alternativas.push({
        fecha: horaAsignada.toISOString().split('T')[0],
        hora: horaAsignada.toTimeString().split(' ')[0].substr(0, 5),
        local: 'Local principal',
        anden: `Andén ${i + 1}`,
        cantidadEnviada: Math.ceil(solicitud.cantidadSolicitada / solicitud.numeroCamiones),
        rondaActual: i + 1,
        totalRondas: solicitud.numeroCamiones
      });
    }

    return alternativas;
  };

  const calcularFillRate = (solicitado: number, enviado: number) => {
    return (enviado / solicitado) * 100;
  };

  return (
    <div className="p-4 border border-blue-500 bg-blue-50 rounded-lg shadow-sm">
      <h2 className="text-xl font-bold mb-2 text-blue-700">Resultado del Algoritmo</h2>
      <p className="text-gray-700 mb-2">{decision.message}</p>
      {decision.status === 'asignado' && decision.alternativas.length > 0 && (
        <div className="mt-2 space-y-4">
          {decision.alternativas.map((alternativa, index) => (
            <div key={index} className="p-3 bg-white border border-gray-300 rounded-lg shadow-sm">
              <h3 className="font-semibold text-lg text-gray-800">Camión {index + 1}</h3>
              <p className="text-gray-600">Fecha: {alternativa.fecha}</p>
              <p className="text-gray-600">Hora: {alternativa.hora}</p>
              <p className="text-gray-600">Local: {alternativa.local}</p>
              <p className="text-gray-600">Andén: {alternativa.anden}</p>
              <p className="text-gray-600">Cantidad a enviar: {alternativa.cantidadEnviada} {solicitud.unidadMedida}</p>
              <div className="mt-2">
                <p className="text-gray-600 font-semibold">
                  Fill Rate: {calcularFillRate(solicitud.cantidadSolicitada, alternativa.cantidadEnviada * solicitud.numeroCamiones).toFixed(2)}%
                </p>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-1">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full" 
                    style={{width: `${calcularFillRate(solicitud.cantidadSolicitada, alternativa.cantidadEnviada * solicitud.numeroCamiones)}%`}}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DecisionAlgorithm;