import React from 'react';
import { Link } from 'react-router-dom';

interface Local {
  id: string;
  nombre: string;
  direccion: string;
}

const localesMock: Local[] = [
  { id: '1', nombre: 'Local Central', direccion: 'Av. Principal 123' },
  { id: '2', nombre: 'Local Norte', direccion: 'Calle Norte 456' },
  { id: '3', nombre: 'Local Sur', direccion: 'Paseo Sur 789' },
];

const AgendaProveedores: React.FC = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Agenda de Locales</h2>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {localesMock.map((local) => (
          <Link 
            key={local.id} 
            to={`/dashboard/agenda-proveedores/${local.id}`}
            className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-300"
          >
            <h3 className="text-lg font-semibold text-blue-600">{local.nombre}</h3>
            <p className="text-gray-600">{local.direccion}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AgendaProveedores;
