import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import { Alternativa } from '../types';

const StyledCalendarWrapper = styled.div`
  .rbc-calendar {
    color: #333;
  }
  .rbc-event {
    background-color: #3174ad;
    color: white;
  }
  .rbc-today {
    background-color: #eaf6ff;
  }
  .rbc-off-range-bg {
    background-color: #f0f0f0;
  }
`;

const localizer = momentLocalizer(moment);

interface Evento {
  id: number;
  title: string;
  start: Date;
  end: Date;
  resourceId?: string;
}

interface Recurso {
  id: string;
  title: string;
}

interface AgendaVisualizacionProps {
  alternativas: Alternativa[];
}

const AgendaVisualizacion: React.FC<AgendaVisualizacionProps> = ({ alternativas }) => {
  // Convertir alternativas a eventos
  const eventos: Evento[] = alternativas.map((alt, index) => {
    const fecha = moment(`${alt.fecha} ${alt.hora}`, 'YYYY-MM-DD HH:mm').toDate();
    return {
      id: index + 1,
      title: `Camión ${index + 1} - ${alt.cantidadEnviada} unidades`,
      start: fecha,
      end: moment(fecha).add(1, 'hour').toDate(), // Asumimos 1 hora de duración
      resourceId: alt.anden,
    };
  });

  const recursos: Recurso[] = [
    { id: 'Andén 1', title: 'Andén 1' },
    { id: 'Andén 2', title: 'Andén 2' },
    { id: 'Andén 3', title: 'Andén 3' },
  ];

  return (
    <StyledCalendarWrapper style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={eventos}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        views={['month', 'week', 'day']}
        step={60}
        showMultiDayTimes
        resources={recursos}
        resourceIdAccessor="id"
        resourceTitleAccessor="title"
        style={{ height: '100%' }}
      />
    </StyledCalendarWrapper>
  );
};

export default AgendaVisualizacion;