import React from 'react';
import { Link } from 'react-router-dom';

interface Solicitud {
  id: string;
  tipoCarga: string;
  cantidadPallets: number;
  fecha: string;
  estado: string;
}

const solicitudesMock: Solicitud[] = [
  { id: '1', tipoCarga: 'Perecederos', cantidadPallets: 10, fecha: '2023-05-15', estado: 'Pendiente' },
  { id: '2', tipoCarga: 'No Perecederos A', cantidadPallets: 15, fecha: '2023-05-18', estado: 'Aprobada' },
  { id: '3', tipoCarga: 'No Perecederos B', cantidadPallets: 8, fecha: '2023-05-20', estado: 'En proceso' },
];

const EstadoSolicitudes: React.FC = () => {
  return (
    <div className="bg-white text-gray-800">
      <h2 className="text-2xl font-bold mb-4">Estado de mis solicitudes</h2>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {solicitudesMock.map((solicitud) => (
          <div key={solicitud.id} className="bg-white p-4 rounded-lg shadow border border-gray-200">
            <h3 className="text-lg font-semibold mb-2">Solicitud #{solicitud.id}</h3>
            <p><span className="font-medium">Tipo de carga:</span> {solicitud.tipoCarga}</p>
            <p><span className="font-medium">Cantidad de pallets:</span> {solicitud.cantidadPallets}</p>
            <p><span className="font-medium">Fecha:</span> {solicitud.fecha}</p>
            <p className="mt-2">
              <span className="font-medium">Estado:</span>{' '}
              <span className={`px-2 py-1 rounded text-white ${
                solicitud.estado === 'Pendiente' ? 'bg-yellow-500' :
                solicitud.estado === 'Aprobada' ? 'bg-green-500' :
                solicitud.estado === 'En proceso' ? 'bg-blue-500' : 'bg-gray-500'
              }`}>
                {solicitud.estado}
              </span>
            </p>
            <Link 
              to={`/dashboard/detalle-solicitud/${solicitud.id}`} 
              className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Ver Detalle
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EstadoSolicitudes;